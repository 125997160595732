const scriptRel = "modulepreload";
const assetsURL = function(dep, importerUrl) {
  return new URL(dep, importerUrl).href;
};
const seen = {};
const __vitePreload = function preload(baseModule, deps, importerUrl) {
  if (!deps || deps.length === 0) {
    return baseModule();
  }
  const links = document.getElementsByTagName("link");
  return Promise.all(deps.map((dep) => {
    dep = assetsURL(dep, importerUrl);
    if (dep in seen)
      return;
    seen[dep] = true;
    const isCss = dep.endsWith(".css");
    const cssSelector = isCss ? '[rel="stylesheet"]' : "";
    const isBaseRelative = !!importerUrl;
    if (isBaseRelative) {
      for (let i = links.length - 1; i >= 0; i--) {
        const link2 = links[i];
        if (link2.href === dep && (!isCss || link2.rel === "stylesheet")) {
          return;
        }
      }
    } else if (document.querySelector(`link[href="${dep}"]${cssSelector}`)) {
      return;
    }
    const link = document.createElement("link");
    link.rel = isCss ? "stylesheet" : scriptRel;
    if (!isCss) {
      link.as = "script";
      link.crossOrigin = "";
    }
    link.href = dep;
    document.head.appendChild(link);
    if (isCss) {
      return new Promise((res, rej) => {
        link.addEventListener("load", res);
        link.addEventListener("error", () => rej(new Error(`Unable to preload CSS for ${dep}`)));
      });
    }
  })).then(() => baseModule()).catch((err) => {
    const e = new Event("vite:preloadError", { cancelable: true });
    e.payload = err;
    window.dispatchEvent(e);
    if (!e.defaultPrevented) {
      throw err;
    }
  });
};
function media({ query }) {
  const mediaQuery = window.matchMedia(query);
  return new Promise(function(resolve) {
    if (mediaQuery.matches) {
      resolve(true);
    } else {
      mediaQuery.addEventListener("change", resolve, { once: true });
    }
  });
}
function visible({ element, options = {} }) {
  const _options = Object.assign(
    {
      root: document.querySelector("[data-scroll-content]"),
      rootMargin: "0px",
      threshold: 0
    },
    options
  );
  return new Promise(function(resolve) {
    const observer = new window.IntersectionObserver(async function(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          observer.disconnect();
          resolve(true);
          break;
        }
      }
    }, _options);
    observer.observe(element);
  });
}
function idle() {
  return new Promise(function(resolve) {
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(resolve);
    } else {
      setTimeout(resolve, 200);
    }
  });
}
function revive(island, islands, isClass = false) {
  async function dfs(node, isClass2) {
    if (node.hasAttribute("client:visible")) {
      const options = node.getAttribute("client:visible").length ? JSON.parse(node.getAttribute("client:visible")) : {};
      await visible({ element: node, options });
    }
    const clientMedia = node.getAttribute("client:media");
    if (clientMedia) {
      await media({ query: clientMedia });
    }
    if (node.hasAttribute("client:idle")) {
      await idle();
    }
    if (isClass2) {
      await islands[island]().then((Class) => new Class.default(node));
    } else {
      islands[island]();
      node.setAttribute("data-ready", true);
    }
  }
  const splitPath = island.split("/");
  const tagName = pascalToKebab(splitPath[splitPath.length - 1].replace(".js", ""));
  const selector = isClass ? `[data-component=${tagName}]` : tagName;
  for (let node of Array.from(document.querySelectorAll(selector))) {
    dfs(node, isClass);
  }
}
function pascalToKebab(str) {
  return str.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`).replace(/^-/, "");
}
export {
  __vitePreload as _,
  revive as r
};
